<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-card>

            <h4>اطلاعات مانترا</h4>

            <!-- Media -->
            <b-media class="my-2 mx-auto">
              <template>
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="400px"
                    rounded
                />
              </template>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic"
                  >
                  <span class="d-none d-sm-inline">+ عکس</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="deleteProductPic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

            <div>
              <validation-observer
                  ref="refFormObserver"
              >

                <b-form>
                  <b-row>

                    <!-- Field:  Title -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                      <b-form-group
                          label="عنوان"
                          label-for="Title"
                      >
                        <b-form-input
                            id="Title"
                            v-model="defaultData.title"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Field: isAvailable -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                      <b-form-group
                          label="وضعیت"
                          label-for="isAvailable"
                      >
                        <v-select
                            v-model="defaultData.isActive"
                            :options="isAvailableOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="isAvailable"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Field:  Title -->
                    <b-col
                        cols="12"
                    >
                      <b-form-group
                          label="جمله مانترا"
                          label-for="summary"
                      >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                        >
                          <b-form-input
                              id="summary"
                              :state="errors.length > 0 ? false:null"
                              v-model="defaultData.summary"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Field:  publishDate -->
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          label="تاریخ و زمان شروع انتشار"
                          label-for=""
                      >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                        >
                          <input
                              type="text"
                              :id="`start${1}`"
                              placeholder="شروع انتشار"
                              :value="createJalaliDate(defaultData.publishDate)"
                              :state="errors.length > 0 ? false:null"
                              class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                          />
                        </validation-provider>
                        <custom-date-picker
                            v-model="defaultData.publishDate"
                            auto-submit
                            input-format="YYYY-MM-DD HH:mm"
                            format="YYYY-MM-DD HH:mm"
                            display-format="jYYYY/jMM/jDD HH:mm"
                            class=""
                            :element="`start${1}`"
                            type="datetime"
                            color="#5c6bc0"
                            :min="new Date()"
                        ></custom-date-picker>
                      </b-form-group>
                    </b-col>

                    <!-- Field:  endTime -->
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <b-form-group
                          label="تاریخ و زمان اتمام انتشار"
                          label-for=""
                      >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                        >
                          <input
                              type="text"
                              :id="`end${1}`"
                              placeholder="اتمام انتشار"
                              :value="createJalaliDate(defaultData.endTime)"
                              :state="errors.length > 0 ? false:null"
                              class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                          />
                        </validation-provider>
                        <custom-date-picker
                            v-model="defaultData.endTime"
                            auto-submit
                            input-format="YYYY-MM-DD HH:mm"
                            format="YYYY-MM-DD HH:mm"
                            display-format="jYYYY/jMM/jDD HH:mm"
                            class=""
                            :element="`end${1}`"
                            type="datetime"
                            color="#5c6bc0"
                            :min="new Date()"
                        ></custom-date-picker>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-form>

              </validation-observer>

              <b-button
                  variant="primary"
                  class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="validationForm"
              >
                ثبت
              </b-button>

            </div>
          </b-card>

        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ProductCategoryGetAllRequest} from "@/libs/Api/Product";
import {ProductCategoryPropertyGetByCategoryIdRequest} from "@/libs/Api/Product";
import {ProductCreateRequest} from "@/libs/Api/Product";
import {ProductCourseGetAllRequest} from "@/libs/Api/Product";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductCategoryGetAllProductCategoriesRequest} from "@/libs/Api/Product";
import {ProductCategoryGetAllCourseCategoriesRequest} from "@/libs/Api/Product";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations'
import {MantraCreateRequest} from "@/libs/Api/Mantra";

export default {
  title: "افزودن آیتم مانترا - پنل ادمین مکس ",
  name: "MantraAdd",
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      overlay: false,
      defaultData: {
        isDeleted: false,
        mantraId: 0,
        title: "",
        summary: "",
        isActive: false,
        createDate: new Date(Date.now()),
        publishDate: "",
        endTime: "",
        mediaFile: {
          base64: "",
          priority: 1
        }
      },
      isAvailableOptions: [
        {label: 'فعال', value: true},
        {label: 'غیرفعال', value: false}
      ],
      base64ImageSrc: null,
    }
  },
  computed: {
    defaultDataImageFile() {
      return this.defaultData.imageFile
    }
  },
  methods: {
    validationForm() {
      let _this = this;

      _this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          _this.createMantra();
        }
      })
    },
    async createMantra() {
      let _this = this;
      _this.overlay = true;

      let mantraCreateRequest = new MantraCreateRequest(_this);
      mantraCreateRequest.setParams(_this.defaultData);
      await mantraCreateRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({name: 'apps-mantra-list'})
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e) {
      const _this = this;
      let file = e.target.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      _this.defaultData.mediaFile.base64 = await _this.readAsDataURL(file);
    },
    createJalaliDate(param) {
      if (param !== null || param !== '') {
        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    deleteProductPic() {
      this.defaultData.mediaFile.base64 = '';
      this.base64ImageSrc = null
    },
  },
}
</script>

<style scoped>

</style>