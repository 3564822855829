import WebServiceRequest from '../Api/WebServiceRequest'

class MantraCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Mantra/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class MantraDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Mantra/Delete')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class MantraGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Mantra/GetAll');
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class MantraGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Mantra/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class MantraUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Mantra/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class MantraGetAllFilterRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Mantra/GetAllFilter')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    MantraCreateRequest,
    MantraDeleteRequest,
    MantraGetAllRequest,
    MantraGetRequest,
    MantraUpdateRequest,
    MantraGetAllFilterRequest
};
